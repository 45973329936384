import * as React from "react";
import { MDBContainer } from "mdbreact";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

const Submitted = (props) => {
  return (
    <>
      <Header />
      <MDBContainer>
        <section className="my-5" style={{ minHeight: "60vh" }}>
          <h2 className="h1-responsive font-weight-bold text-center my-5">
            Something Went Wrong! Please Contact Us for Further Query
          </h2>
          <p className="grey-text text-center w-responsive mx-auto mb-5">
            Please Contact us on support@grynn.ch
          </p>
        </section>
      </MDBContainer>
      <Footer />
    </>
  );
};

export default Submitted;
